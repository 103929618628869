import "./news-tile.scss";
import React from "react";
import { Link } from "gatsby";

const NewsTile = ({
   thumbnail,
   title,
   desc,
   link,
   category,
   categoryColor,
   date,
   isNews,
}) => {
   return (
      <Link to={isNews ? `/aktualnosci${link}` : link} className="news-tile">
         <div className="news-tile__thumbnail">
            <img
               src={
                  thumbnail
                     ? thumbnail.sourceUrl
                     : require("assets/images/news-placeholder.jpg").default
               }
               alt={thumbnail?.altText}
               className="news-tile__thumbnail-img"
            />
            <div
               className="news-tile__category"
               style={{ backgroundColor: categoryColor }}
            >
               {category}
            </div>
            <div className="news-tile__date">{date}</div>
         </div>
         <div className="news-tile__content-wrapper">
            <h3 className="news-tile__title">{title}</h3>
            <div className="news-tile__divider" />
            <div className="news-tile__desc">
               {desc && desc.length > 75 ? `${desc.substring(0, 75)}...` : desc}
            </div>

            <div className="news-tile__button">Czytaj dalej</div>
         </div>
      </Link>
   );
};

export default NewsTile;
