import "styles/pages/single-contest.scss";
import React, { useState } from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import Breadcrumbs from "components/Breadcrumbs";
import SectionTitle from "components/SectionTitle";
import Tabs from "components/Tabs";
import NewsTile from "components/NewsTile";
import classNames from "classnames";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { graphql } from "gatsby";
import ReactPlayer from "react-player";

const SingleContestPage = ({ pageContext, data }) => {
   const [modal, setModal] = useState(false);
   const [videoUrl, setVideoUrl] = useState("");
   const [photoIndex, setPhotoIndex] = useState(0);
   const [isLightboxOpen, setIsLightboxOpen] = useState(false);
   const page = pageContext.data;
   const seo = pageContext.seo;
   const gallery = page.galleryImg?.map((item) => item.sourceUrl);
   const tabsContent = page.tabs;
   const posts = data.allWpPost.nodes;

   return (
      <Layout>
         <Seo title={seo.title ? seo.title : pageContext.title} />
         <Breadcrumbs
            currentLocationLabel={pageContext.title}
            prevLocationLabel={`${
               page.status === "Zakończony"
                  ? "Zakończone konkursy"
                  : "Trwające konkursy"
            }`}
            prevLocationLink={`${
               page.status === "Zakończony"
                  ? "/konkursy-zakonczone"
                  : "/trwajace-konkursy"
            }`}
         />
         <section className="single-contest">
            <div className="container">
               <SectionTitle title={pageContext.title} />
               <div className="row">
                  <div className="col-xl-4 col-lg-5">
                     <div className="single-contest__status-text">
                        Status konkursu:{" "}
                        <span
                           className={classNames(`single-contest__status`, {
                              [`single-contest__status--active`]:
                                 page.status === "Aktywny",
                              [`single-contest__status--finished`]:
                                 page.status === "Zakończony",
                           })}
                        >
                           {page.status}
                        </span>
                     </div>
                     <div
                        className="single-contest__thumbnail"
                        style={{
                           backgroundImage: `url(${page.mainImage?.sourceUrl})`,
                        }}
                     />
                  </div>
                  <div className="col-lg-7 offset-xl-1">
                     <Tabs items={tabsContent} />
                  </div>
               </div>
               <div className="single-contest__section-title">Załączniki</div>
               <div className="row">
                  {page.galleryImg?.map((item, index) => {
                     return (
                        <div className="col-xl-2 col-lg-3 col-md-4" key={index}>
                           <div
                              className="single-contest__file"
                              style={{
                                 backgroundImage: `url(${item.sourceUrl})`,
                              }}
                              onClick={(e) => {
                                 e.preventDefault();
                                 setIsLightboxOpen(true);
                                 setPhotoIndex(index);
                              }}
                              aria-hidden="true"
                           />
                        </div>
                     );
                  })}
                  {page.galleryVideo?.map((item, index) => {
                     return (
                        <div className="col-xl-2 col-lg-3 col-md-4" key={index}>
                           <div
                              className="single-contest__file single-contest__file--video"
                              style={{
                                 backgroundImage: `url(${item.thumbnail?.sourceUrl})`,
                              }}
                              onClick={(e) => {
                                 e.preventDefault();
                                 setModal(true);
                                 setVideoUrl(item.link);
                              }}
                              aria-hidden="true"
                           >
                              <img
                                 src={
                                    require("assets/icons/play-video.svg")
                                       .default
                                 }
                                 className="img-fluid single-contest__play-arrow"
                                 alt="Strzałka"
                              />
                           </div>
                        </div>
                     );
                  })}
               </div>
               <div className="single-contest__section-title">Aktualności</div>
               <div className="row">
                  {posts?.map((item, index) => (
                     <div className="col-lg-4 col-md-6">
                        <NewsTile
                           thumbnail={item.featuredImage?.node}
                           title={item.title}
                           link={item.uri}
                           category={item.categories.nodes[0].name}
                           date={item.date}
                        />
                     </div>
                  ))}
               </div>
            </div>
         </section>
         {isLightboxOpen && (
            <Lightbox
               mainSrc={gallery[photoIndex]}
               nextSrc={gallery[(photoIndex + 1) % gallery.length]}
               prevSrc={
                  gallery[(photoIndex + gallery.length - 1) % gallery.length]
               }
               onCloseRequest={() => setIsLightboxOpen(false)}
               onMovePrevRequest={() =>
                  setPhotoIndex(
                     (photoIndex + gallery.length - 1) % gallery.length
                  )
               }
               onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % gallery.length)
               }
            />
         )}
         {modal && (
            <>
               <div className="subpage-modal subpage-modal--main-modal">
                  <div className="subpage-modal__wrapper">
                     <button
                        className="subpage-modal__close"
                        onClick={(e) => setModal(false)}
                     >
                        &#10005;
                     </button>
                     <div className="embed-container">
                        <ReactPlayer
                           url={videoUrl}
                           width="100%"
                           heigth="100%"
                           playing={modal}
                           controls={true}
                           muted={modal}
                           playsinline={true}
                        />
                     </div>
                  </div>
               </div>
               <div
                  className="subpage-modal__overlay"
                  onClick={(e) => setModal(false)}
                  role="presentation"
               ></div>
            </>
         )}
      </Layout>
   );
};

export const query = graphql`
   query {
      allWpPost(limit: 3) {
         nodes {
            featuredImage {
               node {
                  sourceUrl
               }
            }
            title
            uri
            categories {
               nodes {
                  name
               }
            }
            date(formatString: "DD.MM.y")
         }
      }
   }
`;

export default SingleContestPage;
